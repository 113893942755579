<style lang="less" scoped>
/deep/ .ant-form-item-label {
  text-align: left !important;
  width: 80px;
}
.role-jurisdiction{
  min-height: 180px;
  overflow-y: auto;
  padding-left: 20px;
  box-sizing: border-box;
  .ant-checkbox-group{
    display: flex;
    flex-wrap: wrap;
    /deep/ .ant-checkbox-wrapper{
      width: 50%;
      height: 35px;
      line-height: 35px;
      margin-right: 0;
    }
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 84%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
</style>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">

          <a-col :md="4" :sm="24">
            <a-form-item label="">
              <a-select v-model="queryParam.queryType" placeholder="请选择" default-value="0">
                <a-select-option value="1">姓名</a-select-option>
                <a-select-option value="2">手机号</a-select-option>
                <a-select-option value="3">邮箱</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="">
              <a-input v-model="queryParam.name" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="(!advanced && 8) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_user_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 1100 , y: scrollHeight}"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:but_user_edit>编辑&ensp;|&ensp;</a>
          <!--            <a @click="handleDelUser(record)" >删除</a>|-->
          <a @click="updateStatus(record)" v-action:but_user_disable>{{ record.status == 1 ? '停用' : '启用' }}&ensp;|&ensp;</a>
          <a @click="handleBind(record)" v-action:but_user_bind>绑定角色</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="adduser"
      title="新增/编辑"
      :width="550"
      :visible="visible_adduser"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addUserCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <a-form-item
            label="头像"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-upload
              v-model:file="tabObj.avatarUrl"
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :headers="headers"
              action="/service_provider/user/uploadUserAvatar"
              @change="uploadChange"
            >
              <img style="line-height: 18px" id="imageUrl" :src="imageUrl" width="100px" height="100px" alt="点击上传"/>

            </a-upload>
          </a-form-item>

          <a-form-item
            label="员工姓名"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" name="name" />
          </a-form-item>

          <a-form-item
            label="手机号"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['mobile', { rules: [{ required: true, message: '请输入手机号' }] }]"
              :disabled="disable"
              name="mobile"
            />
          </a-form-item>

          <a-form-item
            label="邮箱"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-input v-decorator="['email', { rules: [{ required: true, message: '请输入邮箱' }] }]" name="email" />
          </a-form-item>
          <a-form-item
            label="企业微信"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
           <a>关联企业微信</a>
          </a-form-item>

          <a-form-item
            label="状态"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-radio-group
              :default-value="1"
              v-decorator="['status']" name="status"
            >
              <a-radio :value="1">启用</a-radio>
              <a-radio :value="0">停用</a-radio>
            </a-radio-group>
          </a-form-item>

<!--          <a-form-item-->
<!--            label="密码"-->
<!--            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
<!--            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"-->
<!--          >-->
<!--            <a-input-->
<!--              v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"-->
<!--              name="password"-->
<!--            />-->
<!--          </a-form-item>-->

          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 10px" @click="addUserCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <!-- 绑定角色弹窗 -->

    <a-modal
      key="bindRole"
      title="绑定角色"
      :width="550"
      :visible="visible_bindrole"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="bindUserRoleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ minheight: '300px' }"
    >
      <div class="role-jurisdiction">
        <a-checkbox-group v-model="defaultCheckedList" :options="roleOptions" @change="roleSelect"> </a-checkbox-group>
      </div>
      <div style="text-align: right;margin:20px 0 0 0">
        <a-button style="margin-right: 18px" @click="bindUserRoleCancel">取消</a-button>
        <a-button @click="bindUserRole" type="primary">提交</a-button>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import STree from '@/components/Tree/Tree'
import { Tree } from 'ant-design-vue'
import {
  findPageUser,
  getRoleList,
  saveUser,
  deleteUser,
  updateUser,
  getUserRoleList,
  bindUserRole, updateUserStatus,
} from '@/api/manage'
import Vue from "vue";
import {ACCESS_TOKEN} from "@/store/mutation-types";
export default {
  name: 'TableList',
  components: {
    STable,
    STree,
    Tree,
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      // 查询参数
      queryParam: {},
      roleOptions: [],
      // 表头
      columns: [
        {
          title: '姓名',
          align: 'center',
          key: 'name',
          dataIndex: 'name',

        },
        {
          title: '手机号',
          align: 'center',
          key: 'mobile',
          dataIndex: 'mobile',

        },
        {
          title: '邮箱',
          align: 'center',
          key: 'email',
          dataIndex: 'email',

        },
        {
          title: '组织',
          align: 'center',
          key: 'orgName',
          dataIndex: 'orgName',

        },
        {
          title: '状态',
          align: 'center',
          key: 'status',
          dataIndex: 'status',

          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '启用'
            } else {
              ret = '停用'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        return findPageUser(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_adduser: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      visible_bindrole: false,
      thisUserId: null, //当前选择的角色id
      roleList: [],
      disable: false,
      defaultCheckedList: [], //初始勾选的角色（已绑定的）
      roleIdSelect: [], //当前选择的角色
      scrollHeight: 0,
      avatarUrl:'',
      imageUrl:''
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 300
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      this.visible_adduser = true
      this.tabObj = record
      this.addFlag = false
      //禁用手机号
      this.disable = true
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: record.name,
          mobile: record.mobile,
          email: record.email,
          status:record.status
        })
        let url = '/ly_user_center/sso/getUserAvatar?fileName=' + record.avatarUrl
        this.imageUrl = url
        this.tabObj.avatarUrl = record.avatarUrl
      })
    },
    handleDelUser(record) {
      deleteUser(record)
        .then((res) => {
          //提交成功
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('删除失败')
        })
    },
    handleOk() {},
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addRoleHandleCancel() {
      this.visible_addrole = false
    },
    handleAdd() {
      this.visible_adduser = true
      this.tabObj = {}
      this.addFlag = true
      this.disable = false
      this.imageUrl = null
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          if (this.addFlag) {
            //新增员工
            values.avatarUrl = this.tabObj.avatarUrl
            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if ( !reg.test(values.mobile )) {
              this.$message.error('手机号不正确')
              return false
            }

            saveUser(values)
              .then((res) => {
                if(res.code==0){
                  //提交成功
                  this.$message.success('保存成功')
                  this.visible_adduser = false
                  this.$refs.table.refresh()
                }else{
                  this.$message.error(res.message)
                }

              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          } else {
            //修改员工
            values.id = this.tabObj.id
            values.avatarUrl = this.tabObj.avatarUrl
            updateUser(values)
              .then((res) => {
                //提交成功
                if(res.code==0){
                this.$message.success('保存成功')
                this.visible_adduser = false
                this.$refs.table.refresh()
                }else{
                  this.$message.error(res.message)
                }
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          }
        }
      })
    },
    addUserCancel() {
      this.visible_adduser = false
    },
    bindUserRoleCancel() {
      this.visible_bindrole = false
    },
    handleBind(e) {
      this.visible_bindrole = true
      this.thisUserId = e.id
      let that = this
      //候选数据
      getRoleList({}).then((res) => {
        //this.roleList = res.result
        this.roleOptions = []
        if (res.result != null) {
          for (let i in res.result) {
            this.roleOptions.push({ label: res.result[i].name, value: res.result[i].id })
          }
        }
      })
      this.$nextTick(() => {
        //请求回显数据
        getUserRoleList({ id: this.thisUserId }).then((res2) => {
          // 勾选已经绑定的角色
          if (res2.result != null) {
            this.defaultCheckedList = []
            for (let i in res2.result) {
              this.defaultCheckedList.push(res2.result[i].id)
              that.roleIdSelect.push(res2.result[i].id)
            }
          }
        })
      })
    },
    roleSelect(e) {
      //选择roleSelect
      console.info(e)
      this.roleIdSelect = e
    },
    bindUserRole() {
      let param = {}
      param.userId = this.thisUserId
      param.roleIdList = this.roleIdSelect
      if(this.roleIdSelect==null||this.roleIdSelect.length<=0){
        this.$message.error("角色不能为空")
        return false
      }

      bindUserRole(param)
        .then((res) => {
          this.$message.success('保存成功')
        })
        .catch((e) => {
          this.$message.error('保存失败')
        })
      this.visible_bindrole = false
      this.roleIdSelect = []
    },
    updateStatus(e) {
      let targetstatus = 1
      if (e.status == 1) {
        targetstatus = 0
      } else {
        targetstatus = 1
      }
      updateUserStatus({ id: e.id, status: targetstatus })
        .then((res) => {
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    uploadChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        let fileName = info.file.response.result
        let url = '/ly_user_center/sso/getUserAvatar?fileName=' + fileName
        //this.userInfo.avatarUrl = url
        this.imageUrl = url
        this.tabObj.avatarUrl  = fileName
      }
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
</style>